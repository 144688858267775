import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  navigate(event) {
    const url = event.target.value;
    if (url) {
      Turbo.visit(url, { frame: 'product-compare' });
    }
  }

  navigateRight(event) {
    const url = event.target.value;
    if (url) {
      Turbo.visit(url, { frame: 'product-list-right' });
    }
  }
}
