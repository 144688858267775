import { Controller } from '@hotwired/stimulus';

require('jvectormap-next')($);
$.fn.vectorMap('addMap', 'world_mill', require('jvectormap-content/world-mill'));

export default class extends Controller {
  connect() {
    $('#world-map').vectorMap({
      map: 'world_mill',
      backgroundColor: 'transparent',
      scaleColors: ['#C8EEFF', '#0071A4'],
      normalizeFunction: 'polynomial',
      regionStyle: {
        initial: {
          fill: '#D6E1ED',
        },
      },
      hoverOpacity: 0.7,
      hoverColor: false,
      markerStyle: {
        initial: {
          r: 5,
          fill: '#336BB5',
          'fill-opacity': 0.8,
          stroke: '#fff',
          'stroke-width': 1.5,
          'stroke-opacity': 0.9,
        },
        hover: {
          stroke: '#fff',
          'fill-opacity': 1,
          'stroke-width': 1.5,
        },
      },
      focusOn: {
        x: 0.5,
        y: 0.5,
        scale: 1,
      },
      markers: [],
    });

    $('#country-filter').on('change', function () {
      const map = $('#world-map').vectorMap('get', 'mapObject');
      map.removeAllMarkers();
      map.reset();
      const country = $(this).val();
      $('.store').each(function () {
        if (!country || $(this).data('country') == country) {
          $(this).show();
          $(this)
            .find('.js-store')
            .each(function () {
              var store = $(this);
              map.addMarker(store.data('id'), {
                latLng: [store.data('lat'), store.data('lng')],
                name: store.data('name'),
              });
            });
        } else {
          $(this).hide();
        }
      });
      if (country) {
        map.setFocus({
          region: country,
        });
      }
    });
  
    var centerMap = function (scale, lat, lng) {
      config = {
        scale: 5,
        lat: lat,
        lng: lng,
        animate: true,
      };
      $('#world-map').vectorMap('get', 'mapObject').setFocus(config);
    };
  
    var resetForm = function () {
      $('#contact-form-failure').html('');
    };
  
    var addMarkers = function (markers) {
      $('#world-map').vectorMap('get', 'mapObject').addMarkers(markers, []);
    };
  
    $('#message').change(() => resetForm());
    $('#email').change(() => resetForm());
    addMarkers(
      $.map($('.js-store'), function (s) {
        return {
          latLng: [$(s).data('lat'), $(s).data('lng')],
          name: $(s).data('name'),
        };
      })
    );
  
    $('.js-store').on('click', function () {
      centerMap(5, $(this).data('lat'), $(this).data('lng'));
    });
  }
}
